import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import LogisticsFormBasic from "./LogisticsFormBasic";

const LogisticsForm = ({ logisticsRecord,setLogisticsRecord,crop }) => {
  const { user } = useAuth();

  const [logisticsId, setLogisticsId] = useState(null);

  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState('');
  const [environmentalConditionId, setEnvironmentalConditionId] = useState('');

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);
  


  useEffect(() => {
    if (logisticsRecord) {
      setLogisticsId(logisticsRecord.id);

      if (logisticsRecord.environmentalCondition) {
        setEnvironmentalConditionId(logisticsRecord.environmentalCondition.id);
        setEnvironmentalCondition(logisticsRecord.environmentalCondition);
      }
    }
  }, [logisticsRecord]);

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }


    const data = {
      cropId:crop.id,
      giai: logisticsRecord.giai,
      date:logisticsRecord.date,
      expectedDeliveryTime:logisticsRecord.expectedDeliveryTime,
      actualDeliveryTime:logisticsRecord.actualDeliveryTime,
      dispatchTime:logisticsRecord.dispatchTime,
      departureLocation:logisticsRecord.departureLocation,
      logisticsProvider:logisticsRecord.logisticsProvider,
      status:logisticsRecord.status,
      destinationLocation:logisticsRecord.destinationLocation,
      transportMode:logisticsRecord.transportMode,
      comments:logisticsRecord.comments,
      vehicleDetails:logisticsRecord.vehicleDetails,
      documentation:logisticsRecord.documentation,
      documentationFile:logisticsRecord.documentationFile,
      quantity:logisticsRecord.quantity,
      quantityUnit:logisticsRecord.quantityUnit,
      environmentalCondition,
    };


    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/logisticsrecords`, requestOptions);
      if (response.ok) {
        toast.success("The Logistic Record has been successfully created.");
        setShowPreloader(true);
        navigate(`/logisticsrecords/crop/${crop.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the logisticsrecords.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    const data = {
      id: logisticsId,
      cropId:crop.id,
      giai:logisticsRecord.giai,
      date: logisticsRecord.date,
      expectedDeliveryTime: logisticsRecord.expectedDeliveryTime,
      actualDeliveryTime: logisticsRecord.actualDeliveryTime,
      dispatchTime: logisticsRecord.dispatchTime,
      departureLocation: logisticsRecord.departureLocation,
      logisticsProvider: logisticsRecord.logisticsProvider,
      status: logisticsRecord.status,
      destinationLocation: logisticsRecord.destinationLocation,
      transportMode: logisticsRecord.transportMode,
      comments: logisticsRecord.comments,
      vehicleDetails: logisticsRecord.vehicleDetails,
      documentation: logisticsRecord.documentation,
      documentationFile: logisticsRecord.documentationFile,
      quantity: logisticsRecord.quantity,
      quantityUnit:logisticsRecord.quantityUnit,
      environmentalConditionId,
      environmentalCondition
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/logisticsrecords/${logisticsId}`, requestOptions);
      if (response.ok) {
        toast.success("The Logistic record has been successfully updated.");
        setShowPreloader(true);
        navigate(`/logisticsrecords/crop/${crop.id}`);;
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the Logistic record.");
    } finally {
      setShowPreloader(false);
    }
  };



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Logistics Record Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Crop {crop && crop.cropCode + ' - ' + crop.name}</span>
                  <Link to={`/logisticsrecords/crop/${crop && crop.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <LogisticsFormBasic crop={crop} logisticsRecord={logisticsRecord} setLogisticsRecord={setLogisticsRecord} handleSubmit={logisticsId ? handleSubmitEdit : handleSubmitCreate} />
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default LogisticsForm;