import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isSinglePageActive } from "../../utils/daynamicNavigation";
import Preloader from "../common/Preloader";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { runningCompanyHasModule } from "../../appSetup";

function CropTable({ cropList, allowEdit }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [showPreloader, setShowPreloader] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const profileMenuItems = [
        {
            id: 1,
            name: "View",
            icon: "icon-eye1",
            onClick: (process) => {
                handleShowViewModal(process);
            },
        },
        {
            id: 2,
            name: "Edit",
            icon: "icon-edit1",
            routerPath: "/crop/edit",
        },
        {
            id: 3,
            name: "Delete",
            icon: "icon-delete",
            onClick: (process) => {
                handleShowDeleteModal(process);
            },
            authType: 'admin',
        },
        {
            id: 4,
            name: "Field Preparation<br/>Records",
            icon: "icon-address",
            routerPath: "/fieldpreparationrecords/crop",
        },
        {
            id: 5,
            name: "Planting Records",
            icon: "icon-format_color_fill",
            routerPath: "/plantingrecords/crop",
        },
        {
            id: 6,
            name: "Weed Management<br/>Records",
            icon: "icon-toys",
            routerPath: "/weedmanagementrecords/crop",
        },

        {
            id: 7,
            name: "Nutrient Management<br/>Records",
            icon: "icon-cloud-drizzle",
            routerPath: "/nutrientmanagementrecords/crop",
        },

        {
            id: 8,
            name: "Pest Management<br/>Records",
            icon: "icon-bug_report",
            routerPath: "/pestmanagementrecords/crop",
        },

        {
            id: 9,
            name: "Induction Records",
            icon: "icon-circular-graph",
            routerPath: "/inductionrecords/crop",
        },

        {
            id: 10,
            name: "Harvesting Records",
            icon: "icon-shopping_basket",
            routerPath: "/harvestingrecords/crop",
        },
        {
            id: 11,
            name: "Pack House Records",
            icon: "icon-home2",
            routerPath: "/packhouserecords/crop",
        },
        {
            id: 12,
            name: "Manufacturing Records",
            icon: "icon-shopping-basket",
            routerPath: "/manufacturingrecords/crop",
            show: runningCompanyHasModule('MANUFACTURING')
        },
        {
            id: 13,
            name: "Packaging Records",
            icon: "icon-gift",
            routerPath: "/packagingrecords/crop",
        },
        {
            id: 14,
            name: "Transfer Records",
            icon: "icon-truck",
            routerPath: "/transferrecords/crop",
        },
        {
            id: 15,
            name: "Logistics Records",
            icon: "icon-shuffle1",
            routerPath: "/logisticsrecords/crop",
        },
        {
            id: 16,
            name: "Warehouse Records",
            icon: "icon-domain",
            routerPath: "/warehouserecords/crop",
        },
        {
            id: 17,
            name: "Spoilage Reports",
            icon: "icon-cloud-off",
            routerPath: "/spoilagerecords/crop",
        },
        // {
        //     id: 18,
        //     name: "Export Customs Records",
        //     icon: "icon-cloud-off",
        //     routerPath: "/exportcustomsrecords/crop",
        // },
    ];
    
    // Define the path pattern for user profiles
    const $ = require("jquery");

    useEffect(() => {
        if (cropList && cropList.length > 0) {
            //Initialize DataTable when records are available
            $(document).ready(function () {
                const dataTable = $("#data-table").DataTable();

                // Destroy DataTable if it exists
                if (dataTable) {
                    dataTable.destroy();
                }

                $("#data-table").DataTable({
                    // Add any required DataTable options here
                    columnDefs: [
                        { width: "15%", targets: 0 },
                        { width: "12%", targets: 2 }
                    ]
                });
            });
        }
    }, [cropList]);

    const handleShowViewModal = (record) => {
        setSelectedRecord(record);
        setShowViewModal(true);
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedRecord(null);
    };

    const handleShowDeleteModal = (record) => {
        setSelectedRecord(record);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setSelectedRecord(null);
    };

    const handleRemoveCrop = async () => {
        if (!(user && user.user.token)) {
            navigate('/login');
        }

        setShowPreloader(true);
        try {
            if (!selectedRecord) {
                console.error('No selected Record');
                return;
            }

            const requestOptions = {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${user.user.token}`,
                },
                redirect: "follow",
            };

            const response = await fetch(`${baseURL}/crops/${selectedRecord.id}`, requestOptions);
            if (response.ok) {
                toast.success("Crop Record removed successfully");
                handleCloseDeleteModal();
                setShowPreloader(true);
                window.location.reload();
            } else {
                handleNotOkHttpResponse(response);
                return false;
            }
        } catch (error) {
            console.error("Error on removing Crop Record:", error.message);
        } finally {
            setShowPreloader(false);
        }
    };

    return (
        <>
            <Preloader show={showPreloader} />
            <div className="card-body pt-0">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                        <div className="table-responsive" style={{ minHeight: "700px" }}>
                            <table id="data-table" className="table custom-table">
                                <thead className="thead-dark">
                                    <tr>
                                        <th>Code</th>
                                        <th>GTIN</th>
                                        <th>Crop Name</th>
                                        <th>Quantity</th>
                                        <th>Is Organic?</th>
                                        <th>Variety</th>
                                        <th><div className="float-end">Actions</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cropList && cropList.length > 0 ? cropList.map((crop, index) => (
                                        <tr key={index}>
                                            <td>{crop.cropCode ? crop.cropCode : '--'}</td>
                                            <td>{crop.gtin ? crop.gtin : '--'}</td>
                                            <td>{crop.name}</td>
                                            <td>{crop.quantity} {crop.quantityUnit}</td>
                                            <td>{crop.isOrganic ? 'Organic' : 'Not Organic'}</td>
                                            <td>{crop.variety}</td>
                                            <td className="">
                                                <ul>
                                                    <li className="user_setting float-end" style={{ listStyle: "none" }}>
                                                        <div className="dropdown">
                                                            <Link
                                                                className="btn btn-secondary dropdown-toggle dn-1199 ms-2"
                                                                role="button"
                                                                to="#"
                                                                id={"dropdownMenuLink" + crop.id}
                                                                data-bs-toggle="dropdown"
                                                            >
                                                                Actions <i className="fa fa-caret-down"></i>
                                                            </Link>
                                                            <ul
                                                                className="dropdown-menu"
                                                                aria-labelledby={"dropdownMenuLink" + crop.id}
                                                            >
                                                                {profileMenuItems.map((actionMenuItem) => (
                                                                    <>
                                                                        {(actionMenuItem.show === undefined || actionMenuItem.show === true) && (!actionMenuItem.authType || (actionMenuItem.authType === "admin" && allowEdit)) &&
                                                                            <li key={crop.id + '-' + actionMenuItem.id}>
                                                                                {actionMenuItem.onClick ? (
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="dropdown-item"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault(); // Prevent default anchor behavior
                                                                                            actionMenuItem.onClick(crop);
                                                                                        }}
                                                                                        style={
                                                                                            isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                ? { color: "#ff5a5f" }
                                                                                                : undefined
                                                                                        }
                                                                                    >
                                                                                        <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;
                                                                                        <span dangerouslySetInnerHTML={{ __html: actionMenuItem.name }}></span>
                                                                                    </Link>
                                                                                ) : (
                                                                                    <Link
                                                                                        to={actionMenuItem.routerPath + "/" + crop.id} // Changed href to to
                                                                                        className="dropdown-item"
                                                                                        style={
                                                                                            isSinglePageActive(`${actionMenuItem.routerPath}`, navigate.pathname)
                                                                                                ? { color: "#ff5a5f" }
                                                                                                : undefined
                                                                                        }
                                                                                    >
                                                                                        <i className={actionMenuItem.icon}></i>&nbsp;&nbsp;
                                                                                        <span dangerouslySetInnerHTML={{ __html: actionMenuItem.name }}></span>
                                                                                    </Link>
                                                                                )}
                                                                            </li>
                                                                        }
                                                                    </>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )) : (<tr><td colSpan="8" className="text-center"><em>No data available!</em></td></tr>)}
                                </tbody>
                            </table>
                            {/* Delete Modal */}
                            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                                <Modal.Header closeButton style={{ backgroundColor: 'rgb(36, 50, 74)' }}>
                                    <Modal.Title style={{ color: 'white' }}>Delete Crop Record</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p className="mb-4">
                                        Are you sure you want to delete this crop record?
                                    </p>
                                </Modal.Body>
                                <Modal.Footer style={{ borderTop: 'none' }}>
                                    <Button variant="secondary" style={{ color: '#fff', backgroundColor: '#6c757d', border: 'none' }} onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" style={{ color: '#fff', backgroundColor: '#dc3545', border: 'none' }} onClick={handleRemoveCrop}>
                                        Delete
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* View Modal */}
                            <Modal size="lg" show={showViewModal} onHide={handleCloseViewModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Logistics Record Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {selectedRecord ? (
                                        <div className="formView">
                                            <p><strong>Code:</strong> {selectedRecord.code ? selectedRecord.cropCode : "N/A"}</p>
                                            <p><strong>Name:</strong> {selectedRecord.name}</p>
                                            <p><strong>Variety:</strong> {selectedRecord.variety}</p>
                                            <p><strong>Lifespan:</strong> {selectedRecord.lifespan} {selectedRecord.lifespanUnit}</p>
                                            <p><strong>Quantity:</strong> {selectedRecord.quantity} {selectedRecord.quantityUnit}</p>
                                            <p><strong>Purpose:</strong> {selectedRecord.purpose}</p>
                                            <p><strong>isOrganic:</strong> {selectedRecord.isOrganic ? 'Organic' : 'Not Organic'}</p>
                                        </div>
                                    ) : (
                                        <p>No record selected.</p>
                                    )}

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseViewModal}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CropTable;