import { Navigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired } from "../../utils/helpers";
import FileInput from "../common/FileInput";

const FarmerForm = ({ farmerData }) => {
  const { user } = useAuth();
  const [gln, setGln] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [gender, setGender] = useState('');
  const [homeAddress, setHomeAddress] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [startingYearOfFarming, setStartingYearOfFarming] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [otherNames, setOtherNames] = useState('');
  const [designation, setDesignation] = useState('');
  const [baseLevel, setBaseLevel] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [farmerId, setFarmerId] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');




  useEffect(() => {

    if (farmerId) {
      getFarmerData(farmerId);
    }
  }, [farmerId])

  useEffect(() => {
    if (farmerData) {
      setFarmerId(farmerData.id);
      setGln(farmerData.gln);
      setEmail(farmerData.email);
      setFirstName(farmerData.firstName);
      setGender(farmerData.gender);
      setHomeAddress(farmerData.homeAddress);
      setLastName(farmerData.lastName);
      setMobileNumber(farmerData.mobileNumber);
      setPassword(farmerData.password);
      setStartingYearOfFarming(farmerData.startingYearOfFarming);
      setUsername(farmerData.username);
      setProfilePicture(farmerData.profilePicture);
      setOtherNames(farmerData.otherNames);
      setDesignation(farmerData.designation);
      setBaseLevel(farmerData.baseLevel);
    }
  }, [farmerData]);

  const getFarmerData = async () => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/farmers/${farmerId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setGln(data.gln);
        setEmail(data.email);
        setFirstName(data.firstName);
        setGender(data.gender);
        setHomeAddress(data.homeAddress);
        setLastName(data.lastName);
        setMobileNumber(data.mobileNumber);
        setPassword(data.password);
        setStartingYearOfFarming(data.startingYearOfFarming);
        setUsername(data.username);
        setProfilePicture(data.profilePicture);
        setOtherNames(data.otherNames);
        setDesignation(data.designation);
        setBaseLevel(data.baseLevel);
      })
      .catch((error) => {
        toast.error('Error fetching crop data.');
        console.error("Error fetching crop data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!email) {
      fieldWithIdRequired('email');
      toast.error("Email is required.");
      retVal = false;
    }
    if (!firstName) {
      fieldWithIdRequired('firstName');
      toast.error("First Name is required.");
      retVal = false;
    }
    if (!lastName) {
      fieldWithIdRequired('lastName');
      toast.error("Last Name is required.");
      retVal = false;
    }
    if (!mobileNumber) {
      fieldWithIdRequired('mobileNumber');
      toast.error("Mobile Number is required.");
      retVal = false;
    }
    if (!startingYearOfFarming) {
      fieldWithIdRequired('startingYearOfFarming');
      toast.error("Starting Year of Farming is required.");
      retVal = false;
    }
    if (!homeAddress) {
      fieldWithIdRequired('homeAddress');
      toast.error("Home Address is required.");
      retVal = false;
    }
    if (!gender) {
      fieldWithIdRequired('gender');
      toast.error("Gender is required.");
      retVal = false;
    }
    
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      gln,
      email,
      firstName,
      gender,
      homeAddress,
      lastName,
      mobileNumber,
      password,
      startingYearOfFarming,
      username,
      profilePicture,
      otherNames,
      designation,
      baseLevel
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/farmers`, requestOptions);

      if (response.ok) {
        toast.success("The Field has been successfully created.");

        const data = response.json();

        navigate(`/outgrowers`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the field.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: farmerId,
      gln,
      email,
      firstName,
      gender,
      homeAddress,
      lastName,
      mobileNumber,
      startingYearOfFarming,
      otherNames,
      designation,
      baseLevel
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/farmers/${farmerId}`, requestOptions);

      if (response.ok) {
        toast.success("The outgrowers has been successfully updated.");
        setShowPreloader(true);
        navigate(`/outgrowers`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the outgrowers.");
    } finally {
      setShowPreloader(false);
    }
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Outgrower Form</div>
              {/* <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Field: </span>{fieldList.find(field => field.id === fieldId)?.name || ''}
                </div>
              </div> */}

            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="profilePicture">Profile Picture</label>
                    <FileInput id={"profilePicture"} value={profilePicture} setValue={setProfilePicture} required={true} className ="form-control" />
                    {profilePicture && (
                      <img
                        src={profilePicture}
                        alt="Profile Preview"
                        style={{ maxHeight: '100px', marginTop: '10px' }}
                      />
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="email">GLN</label>
                    <input
                      type="text"
                      className="form-control"
                      id="gln"
                      placeholder="Enter the GLN..."
                      value={gln}
                      onChange={(e) => {setGln(e.target.value);}}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter the email..."
                      value={email}
                      onChange={(e) => {setEmail(e.target.value);  fieldRequired(e);}}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      placeholder="Enter the first name..."
                      value={firstName}
                      onChange={(e) => {setFirstName(e.target.value);  fieldRequired(e);}}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      placeholder="Enter the last name..."
                      value={lastName}
                      onChange={(e) => {setLastName(e.target.value);  fieldRequired(e);}}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Other Name(s)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="otherNames"
                      value={otherNames}
                      onChange={(e) => { setOtherNames(e.target.value);}}
                      placeholder="Enter the Other Names..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="gender">Gender *</label>
                    <select
                      type="text"
                      className="form-control"
                      id="gender"
                      value={gender}
                      onChange={(e) => { setGender(e.target.value); fieldRequired(e);}}
                    >
                      <option>Select Gender...</option>
                      <option value={"Male"}>Male</option>
                      <option value={"Female"}>Female</option>
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="designation">Designation</label>
                    <input
                      type="text"
                      className="form-control"
                      id="designation"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      placeholder="Enter the Designation..."
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="baseLevel">Base Level</label>
                    <input
                      type="text"
                      className="form-control"
                      id="baseLevel"
                      value={baseLevel}
                      onChange={(e) => setBaseLevel(e.target.value)}
                      placeholder="Enter the Base Level..."
                    />
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="mobileNumber">Mobile Number *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="mobileNumber"
                      value={mobileNumber}
                      onChange={(e) => { setMobileNumber(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Mobile Number..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                  <label htmlFor="homeAddress">Home Address *</label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="homeAddress"
                      value={homeAddress}
                      onChange={(e) => { setHomeAddress(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Home Address..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                  <label htmlFor="startingYearOfFarming">Starting Year of Farming *</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="startingYearOfFarming"
                      value={startingYearOfFarming}
                      onChange={(e) => { setStartingYearOfFarming(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Starting Year of Farming..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                {/* {(!farmerId &&
                <>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <hr />
                    <h6>User Account</h6>
                    <br />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                    <label htmlFor="username">Username</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="username"
                        value={username}
                        onChange={(e) => { setUsername(e.target.value); fieldRequired(e); }}
                        placeholder="Enter the Username..."
                      />
                      <div className="invalid-feedback">This is required</div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                    <label htmlFor="purpose">Password</label>
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="password"
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); fieldRequired(e); }}
                        placeholder="Enter Password..."
                      />
                      <div className="invalid-feedback">This is required</div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                    <label htmlFor="purpose">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="passwordConfirm"
                        value={passwordConfirm}
                        onChange={(e) => { setPasswordConfirm(e.target.value); fieldRequired(e); }}
                        placeholder="Confirm Password..."
                      />
                      <div className="invalid-feedback">This is required</div>
                    </div>
                  </div>
                </>
                )} */}

              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(farmerId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default FarmerForm;