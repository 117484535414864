import { Navigate } from "react-router";
import useAuth from "../../hooks/useAuth";
import { baseURL, handleNotOkHttpResponse } from "../../utils/misc";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate,useLocation, Link } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldRequired, fieldWithIdRequired } from "../../utils/helpers";
import { getFieldCroppingTypes, getFieldSoilTypes } from "../../api/Fields/getFieldsList";

const FieldForm = ({ fieldData, farm }) => {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [gln, setGln] = useState('');
  const [area, setArea] = useState('');
  const [showPreloader, setShowPreloader] = useState(false);
  const navigate = useNavigate();
  const [fieldId, setFieldId] = useState(null);
  const [fieldCode, setFieldCode] = useState('');
  const [croppingType, setCroppingType] = useState('');
  const [soilType, setSoilType] = useState('');
  const [startingYearOfCultivation, setStartingYearOfCultivation] = useState('');
  
  const [soilTypeList, setSoilTypeList] = useState([]);
  const [croppingTypeList, setCroppingTypeList] = useState([]);
  const [farmList, setFarmList] = useState([]);

  useEffect(() => {
    getSetUpData();
  }, []);

  useEffect(() => {

    getFarmData()
    if (fieldId) {
      getFieldData(fieldId);
    }
  }, [fieldId])

  useEffect(() => {
    if (fieldData) {
      setFieldId(fieldData.id);
      setFieldCode(fieldData.fieldCode)
      setGln(fieldData.gln)
      setName(fieldData.name);
      setArea(fieldData.area);
      setCroppingType(fieldData.croppingType);
      setSoilType(fieldData.soilType);
      setStartingYearOfCultivation(fieldData.startingYearOfCultivation);
      // setUserId(userData.id);
    }
  }, [fieldData]);


  const getFarmData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);
    // Fetch user data when the component mounts
    fetch(`${baseURL}/farms`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error('Error fetching users')
        }
      })
      .then((data) => {
        // Set the useState valuations with the fetched data
        setFarmList(data);
      })
      .catch((error) => {
        toast.error("Error fetching WorkFlowProcess Types.");
      })
      .finally(()=>{
        setShowPreloader(false);
      })
  };

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setSoilTypeList(await getFieldSoilTypes(user.user.token));
      setCroppingTypeList(await getFieldCroppingTypes(user.user.token));
    } catch {} finally {
      setShowPreloader(false);
    }
  };

  const getFieldData = async (fieldId) => {

    if (!(user && user.user.token)) {
      navigate('/login');
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.user.token}`,
      }
    };
    setShowPreloader(true);

    // Fetch service data when the component mounts
    fetch(`${baseURL}/fields/${fieldId}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {

        // Set the useState services with the fetched data
        setFieldCode(data.fieldCode);
        setGln(data.gln);
        setName(data.name);
        setArea(data.area);
        setCroppingType(data.croppingType);
        setSoilType(data.soilType);
        setStartingYearOfCultivation(data.startingYearOfCultivation);

        //setActive(data.active);



      })
      .catch((error) => {
        toast.error('Error fetching field data.');
        console.error("Error fetching field data:", error);
      })
      .finally (()=> {
        setShowPreloader(false);
      })

  };

  const validateFields = () => {
    let retVal = true;

    if (!name) {
      fieldWithIdRequired('name');
      toast.error("Farm Name is required.");
      retVal = false;
    }
    if (!area) {
      fieldWithIdRequired('area');
      toast.error("Farm Area is required.");
      retVal = false;
    }
    if (!soilType) {
      fieldWithIdRequired('soilType');
      toast.error("Soil Type is required.");
      retVal = false;
    }
    if (!startingYearOfCultivation) {
      fieldWithIdRequired('startingYearOfCultivation');
      toast.error("Starting Year of Cultivation is required.");
      retVal = false;
    }

    if (!croppingType) {
      fieldWithIdRequired('croppingType');
      toast.error("Cropping Type is required.");
      retVal = false;
    }
    return retVal;
  };

  const handleSubmitCreate = async (e) => {
    // setShowPreloader(true);
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      farmId:farm.id,
      gln: gln,
      name: name,
      area: area,
      soilType: soilType,
      croppingType: croppingType,
      startingYearOfCultivation: startingYearOfCultivation,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/fields`, requestOptions);

      if (response.ok) {
        toast.success("The Field has been successfully created.");
        setShowPreloader(true);
        navigate(`/fields/farm/${farm.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error creating the field.");
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      return; // Ensure the function stops execution if the user is not authenticated
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id: fieldId,
      farmId:farm.id,
      gln: gln,
      name: name,
      area: area,
      croppingType: croppingType,
      soilType: soilType,
      startingYearOfCultivation: startingYearOfCultivation,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/plain",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
      redirect: "follow",
    };
    setShowPreloader(true);
    try {
      const response = await fetch(`${baseURL}/fields/${fieldId}`, requestOptions);

      if (response.ok) {
        toast.success("The field has been successfully updated.");
        setShowPreloader(true);
        navigate(`/fields/farm/${farm.id}`);
      } else {
        handleNotOkHttpResponse(response);
      }
    } catch (error) {
      toast.error("Error updating the field.");
    } finally {
      setShowPreloader(false);
    }
  };

  



  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Field Form</div>
              <div className="mt-3 p-2 parentLabel">
                <div className="mt-2 mb-1">
                  <span>Field {farm && farm.farmCode + ' - ' + farm.name}</span>
                  <Link to={`/fields/farm/${farm && farm.id}`}><span className="float-end"><i className="icon-arrow-left"></i>Go back</span></Link>
                </div>

              </div>
            </div>
            <div className="card-body pt-0">
              {/* <hr /> */}
              <div className="row gutters">

                {fieldId && 
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                    <div className="form-group">
                      <label htmlFor="name">Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        value={fieldCode ? fieldCode : '--'}
                        readOnly
                      />
                    </div>
                  </div>
                }

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">GLN</label>
                    <input
                      type="text"
                      className="form-control"
                      id="gln"
                      placeholder="Enter the GLN..."
                      value={gln}
                      onChange={(e) => {setGln(e.target.value);}}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="name">Field Name *</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Enter the name of the Field..."
                      value={name}
                      onChange={(e) => {setName(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="lastName">Field Area <em>(acres)</em> *</label>
                    <input
                      type="number"
                      className="form-control"
                      id="area"
                      placeholder="Enter the area of the field..."
                      value={area}
                      onChange={(e) => {setArea(e.target.value); fieldRequired(e); }}
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                  <label htmlFor="startingYearOfCultivation">Starting Year of Cultivation *</label>
                    <input
                      type="number"
                      className="form-control form-control-lg"
                      id="startingYearOfCultivation"
                      value={startingYearOfCultivation}
                      onChange={(e) => { setStartingYearOfCultivation(e.target.value); fieldRequired(e); }}
                      placeholder="Enter the Starting Year of Cultivation..."
                    />
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Soil Type *</label>
                    <select
                      className="form-control form-control-lg"
                      id="soilType"
                      value={soilType}
                      onChange={(e) => { setSoilType(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Soil Type...</option>
                      {soilTypeList && soilTypeList.map((soilList) => (
                        <option key={soilList} value={soilList}>
                          {soilList}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>
                
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
                  <div className="form-group">
                    <label htmlFor="otherNames">Cropping Type *</label>
                    <select
                      className="form-control form-control-lg"
                      id="croppingType"
                      value={croppingType}
                      onChange={(e) => { setCroppingType(e.target.value); fieldRequired(e); }}
                    >
                      <option value="">Select Cropping Type...</option>
                      {croppingTypeList && croppingTypeList.map((cropList) => (
                        <option key={cropList} value={cropList}>
                          {cropList}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">This is required</div>
                  </div>
                </div>

              </div>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
                  <div className="float-end">
                    <button type="button" className="btn btn-primary float-end"
                      onClick={(fieldId ? handleSubmitEdit : handleSubmitCreate)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Row end */}
    </>
  );
}

export default FieldForm;