import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SideBarWrapper from '../../components/common/SideBarWrapper';
import useAuth from '../../hooks/useAuth';
import { baseURL } from '../../utils/misc';
import Navbar from '../../layout/Navbar';
import Preloader from "../../components/common/Preloader";
import FileInput from '../common/FileInput';
import { Link, useNavigate } from 'react-router-dom';
import { fieldRequired, fieldWithIdRequired } from '../../utils/helpers';

function CompanyInfo() {
  const { user } = useAuth();
  const [showPreloader, setShowPreloader] = useState(false);

  // State variables for company information
  const [gln, setGln] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [ownersLastName, setOwnersLastName] = useState('');
  const [ownersFirstName, setOwnersFirstName] = useState('');
  const [ownersOtherNames, setOwnersOtherNames] = useState('');
  const [ownersHomeAddress, setOwnersHomeAddress] = useState('');
  const [startingYearOfBusiness, setStartingYearOfBusiness] = useState('');
  const [logo, setLogo] = useState('');
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState('');
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [typeOfProductsExported, setTypeOfProductsExported] = useState('');
  const [exportDestinations, setExportDestinations] = useState('');
  const [certifications, setCertifications] = useState('');
  const [insuranceInformation, setInsuranceInformation] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');
  const [socialMediaLinks, setSocialMediaLinks] = useState('');
  const [companyInfoId, setCompanyInfoId] = useState(null); // For existing company ID


  const navigate = useNavigate();
  useEffect(() => {
    if (user && user.user) {
      fetchCompanyInfo();
    }
  }, [user]);

  const fetchCompanyInfo = async () => {
    const apiUrl = `${baseURL}/companyinformation/${user.user.id}`; // Adjust API endpoint as needed
    const token = user?.user?.token;
    setShowPreloader(true);

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const companyData = await response.json();
        if (companyData) {
          setCompanyInfoId(companyData.id); // Set ID for existing company info
          updateStates(companyData);
        }
      } else {
        console.error('Failed to fetch company information');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    } finally {
      setShowPreloader(false);
    }
  };

  function updateStates(companyData) {
    setGln(companyData.gln || null);
    setBusinessName(companyData.businessName || '');
    setEmail(companyData.email || '');
    setTelephone(companyData.telephone || '');
    setBusinessAddress(companyData.businessAddress || '');
    setOwnersLastName(companyData.ownersLastName || '');
    setOwnersFirstName(companyData.ownersFirstName || '');
    setOwnersOtherNames(companyData.ownersOtherNames || '');
    setOwnersHomeAddress(companyData.ownersHomeAddress || '');
    setStartingYearOfBusiness(companyData.startingYearOfBusiness || '');
    setLogo(companyData.logo || '');
    setBusinessRegistrationNumber(companyData.businessRegistrationNumber || '');
    setTaxIdentificationNumber(companyData.taxIdentificationNumber || '');
    setWebsite(companyData.website || '');
    setTypeOfProductsExported(companyData.typeOfProductsExported || '');
    setExportDestinations(companyData.exportDestinations || '');
    setCertifications(companyData.certifications || '');
    setInsuranceInformation(companyData.insuranceInformation || '');
    setYearsOfExperience(companyData.yearsOfExperience || '');
    setBusinessDescription(companyData.businessDescription || '');
    setSocialMediaLinks(companyData.socialMediaLinks || '');
  }

  
  const validateFields = () => {
    let retVal = true;
    if (!businessName) {
      fieldWithIdRequired('businessName');
      toast.warn("Business Name is required.");
      retVal = false;
    }
    if (!email) {
      fieldWithIdRequired('email');
      toast.warn("Email is required.");
      retVal = false;
    }
    if (!telephone) {
      fieldWithIdRequired('mobileNumber');
      toast.warn("Telephone is required.");
      retVal = false;
    }
    if (!businessAddress) {
      fieldWithIdRequired('businessAddress');
      toast.warn("Business Address is required.");
      retVal = false;
    }
    if (!ownersLastName) {
      fieldWithIdRequired('ownersLastName');
      toast.warn("Owner's Last Name is required.");
      retVal = false;
    }
    if (!ownersFirstName) {
      fieldWithIdRequired('ownersFirstName');
      toast.warn("Owner's First Name is required.");
      retVal = false;
    }
    if (!ownersHomeAddress) {
      fieldWithIdRequired('ownersHomeAddress');
      toast.warn("Owner's Home Address is required.");
      retVal = false;
    }
    if (!startingYearOfBusiness) {
      fieldWithIdRequired('startingYearOfBusiness');
      toast.warn("Starting Year of Business is required.");
      retVal = false;
    }
    return retVal;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!(user && user.user.token)) {
      navigate('/login');
      toast.warn("You seem to have lost your login access. Please try again later.");
      return;
    }

    if (!validateFields()) {
      return;
    }

    const data = {
      id : companyInfoId,
      gln,
      businessName,
      email,
      telephone,
      businessAddress,
      ownersLastName,
      ownersFirstName,
      ownersOtherNames,
      ownersHomeAddress,
      startingYearOfBusiness,
      businessRegistrationNumber,
      taxIdentificationNumber,
      website,
      typeOfProductsExported,
      exportDestinations,
      certifications,
      insuranceInformation,
      yearsOfExperience,
      businessDescription,
      socialMediaLinks,
      logo: logo, // Optional: include logoFile name
    };

    const requestOptions = {
      method: companyInfoId ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify(data),
    };

    const apiUrl = companyInfoId
      ? `${baseURL}/companyinformation/${companyInfoId}`
      : `${baseURL}/companyinformation`;

    setShowPreloader(true);
    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        if(!companyInfoId) {
          const result = await response.json();
          setCompanyInfoId(result.id); // Update ID if necessary
        }
        toast.success("Update successful");
      } else {
        console.error('Failed to update company information');
      }
    } catch (error) {
      toast.error("Error updating the company information.");
    } finally {
      setShowPreloader(false);
    }
  };
  

  return (
    <>
      <Preloader show={showPreloader} />
      <div className="page-wrapper">
        <SideBarWrapper />
        <div className="page-content">
          <Navbar />
          <div className="page-header">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Company Profile</li>
            </ol>
          </div>

          <div className="main-container">
            <div className="row gutters">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="account-settings">
                      <div className="company-profile text-center">
                        <div className="company-logo mb-2">
                          {logo && <img src={logo} alt="Company Logo" />}
                        </div>
                        <h5 className="user-name">{businessName}</h5>
                        <h6 className="user-email">{email}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-5 col-md-4 col-sm-4 col-12">
                <div className="card h-100">
                  <div className="card-header">
                    <div className="card-title">Update Company Info</div>
                  </div>
                  <div className="card-body">
                    <div className="row gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <div className="row gutters">
                            {/* Logo */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="logo">Logo</label>
                                <FileInput
                                  id="logo"
                                  value={logo}
                                  setValue={setLogo}
                                  accept="image/*"
                                  required={false}
                                  className="form-control"
                                />
                              </div>
                            </div>

                            {/* GLN */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="businessName">GLN</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="GLN"
                                  value={gln}
                                  onChange={(e) => { setGln(e.target.value); }}
                                  placeholder="Enter the GLN..."
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Business Name */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="businessName">Business Name *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="businessName"
                                  value={businessName}
                                  onChange={(e) => { setBusinessName(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter the Business Name..."
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Email */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="email">Email *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  value={email}
                                  onChange={(e) => { setEmail(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter Email"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Telephone */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="telephone">Telephone *</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id="telephone"
                                  value={telephone}
                                  onChange={(e) => { setTelephone(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter Telephone"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Business Address */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="businessAddress">Business Address *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="businessAddress"
                                  value={businessAddress}
                                  onChange={(e) => { setBusinessAddress(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter Business Address"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Owner's Last Name */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="ownersLastName">Owner's Last Name *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ownersLastName"
                                  value={ownersLastName}
                                  onChange={(e) => { setOwnersLastName(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter Owner's Last Name"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Owner's First Name */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="ownersFirstName">Owner's First Name *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ownersFirstName"
                                  value={ownersFirstName}
                                  onChange={(e) => { setOwnersFirstName(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter Owner's First Name"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Owner's Other Names */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="ownersOtherNames">Owner's Other Names</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ownersOtherNames"
                                  value={ownersOtherNames}
                                  onChange={(e) => { setOwnersOtherNames(e.target.value); }}
                                  placeholder="Enter Owner's Other Names"
                                />
                              </div>
                            </div>

                            {/* Owner's Home Address */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="ownersHomeAddress">Owner's Home Address *</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ownersHomeAddress"
                                  value={ownersHomeAddress}
                                  onChange={(e) => { setOwnersHomeAddress(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter Owner's Home Address"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Starting Year of Business */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="startingYearOfBusiness">Starting Year of Business *</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="startingYearOfBusiness"
                                  value={startingYearOfBusiness}
                                  onChange={(e) => { setStartingYearOfBusiness(e.target.value); fieldRequired(e); }}
                                  placeholder="Enter Starting Year of Business"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Business Registration Number */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="businessRegistrationNumber">Business Registration Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="businessRegistrationNumber"
                                  value={businessRegistrationNumber}
                                  onChange={(e) => { setBusinessRegistrationNumber(e.target.value); }}
                                  placeholder="Enter Business Registration Number"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Tax Identification Number */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="taxIdentificationNumber">Tax Identification Number</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="taxIdentificationNumber"
                                  value={taxIdentificationNumber}
                                  onChange={(e) => { setTaxIdentificationNumber(e.target.value); }}
                                  placeholder="Enter Tax Identification Number"
                                />
                              </div>
                            </div>

                            {/* Website */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="website">Website</label>
                                <input
                                  type="url"
                                  className="form-control"
                                  id="website"
                                  value={website}
                                  onChange={(e) => { setWebsite(e.target.value); }}
                                  placeholder="Enter Website"
                                />
                              </div>
                            </div>

                            {/* Type of Products Exported */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="typeOfProductsExported">Type of Products Exported</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="typeOfProductsExported"
                                  value={typeOfProductsExported}
                                  onChange={(e) => { setTypeOfProductsExported(e.target.value); }}
                                  placeholder="Enter Type of Products Exported"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>

                            {/* Export Destinations */}
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <label htmlFor="exportDestinations">Export Destinations</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exportDestinations"
                                  value={exportDestinations}
                                  onChange={(e) => { setExportDestinations(e.target.value); }}
                                  placeholder="Enter Export Destinations"
                                />
                                <div className="invalid-feedback">This is required</div>
                              </div>
                            </div>
                            
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="form-group"></div>
                              <div className="text-right">
                                <button type="button" onClick={handleSubmit} className="btn btn-primary">
                                  Submit
                                </button>
                              </div>
                            </div>

                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyInfo;
