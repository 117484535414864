import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Preloader from "../common/Preloader";
import { fieldWithIdRequired, isoToDate } from "../../utils/helpers";
import { getPackagingMaterials, getPackagingQuantityUnit, getPackagingTypes } from "../../api/PackagingRecords/getPackagingList";
import EnvironmentalConditionNew from "../common/EnvironmentalConditionNew";

const PackagingBasicForm = ({ packagingRecord, crop, setPackagingRecord, handleSubmit }) => {
  const { user } = useAuth();

  const [quantityUnitList, setQuantityUnitList] = useState([]);
  const [packagingTypeList, setPackagingTypeList] = useState([]);
  const [packagingMaterialList, setPackagingMaterialList] = useState([]);

  //env conditions data
  const [environmentalCondition, setEnvironmentalCondition] = useState();

  const navigate = useNavigate();
  const [showPreloader, setShowPreloader] = useState(false);

  useEffect(() => {
    getSetUpData();
  }, []);

  // useEffect(() => {

  //   if (packagingId) {
  //     getPackagingRecords(packagingId);
  //   }
  // }, [packagingId])

  useEffect(() => {
    if (packagingRecord) {
      if (packagingRecord.environmentalCondition) {
        setEnvironmentalCondition(packagingRecord.environmentalCondition);
      }
    }
  }, [packagingRecord]);

  const getSetUpData = async () => {
    if (!(user && user.user.token)) {
      navigate('/login');
    }

    try {
      setShowPreloader(true);
      setQuantityUnitList(await getPackagingQuantityUnit(user.user.token));
      setPackagingTypeList(await getPackagingTypes(user.user.token));
      setPackagingMaterialList(await getPackagingMaterials(user.user.token));
    } catch { } finally {
      setShowPreloader(false);
    }
  };


  const validateFields = () => {
    let retVal = true;

    if (!packagingRecord.date) {
      fieldWithIdRequired('date');
      toast.error("Date is required.");
      retVal = false;
    }

    if (!packagingRecord.packagingMaterial) {
      fieldWithIdRequired('packagingMaterial');
      toast.error("Packaging Material is required.");
      retVal = false;
    }

    if (!packagingRecord.packagingType) {
      fieldWithIdRequired('packagingType');
      toast.error("Packaging Type is required.");
      retVal = false;
    }

    if (!packagingRecord.quantityPackaged) {
      fieldWithIdRequired('quantityPackaged');
      toast.error("Quantity  Packaged is required.");
      retVal = false;
    }

    if (!packagingRecord.quantityUnit) {
      fieldWithIdRequired('quantityUnit');
      toast.error("Quantity Unit is required.");
      retVal = false;
    }

    if (!packagingRecord.packagedBy) {
      fieldWithIdRequired('performedBy');
      toast.error("Performed By is required.");
      retVal = false;
    }

    // environmental conditions data

    if (!environmentalCondition.effects) {
      fieldWithIdRequired('effects');
      toast.error("Effects field is required.");
      retVal = false;
    }

    if (!environmentalCondition.recordedDate) {
      fieldWithIdRequired('recordedDate');
      toast.error("Recorded Date is required.");
      retVal = false;
    }

    if (!environmentalCondition.temperature) {
      fieldWithIdRequired('temperature');
      toast.error("Temperature is required.");
      retVal = false;
    }

    if (!environmentalCondition.weatherConditions) {
      fieldWithIdRequired('weatherConditions');
      toast.error("Whether Conditions is required.");
      retVal = false;
    }


    return retVal;
  };

  const handleChange = (field, value, required) => {
    const updatedItem = { ...packagingRecord }; // Create a copy of the items array
    updatedItem[field] = value; // Update the value in the copy
    setPackagingRecord(updatedItem); // Update the state with the modified copy
    if (required) fieldWithIdRequired(field); //Show UI validation
  };

  return (
    <>
      <Preloader show={showPreloader} />
      {/* Row start */}
      <div className="row gutters">

        {packagingRecord && packagingRecord.id &&
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Code</label>
              <input
                type="text"
                className="form-control"
                id="code"
                value={packagingRecord.code ? packagingRecord.code : '--'}
                readOnly
              />
            </div>
          </div>
        }

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="name">Date *</label>
            <input
              type="date"
              className="form-control"
              id="date"
              value={isoToDate(packagingRecord && packagingRecord.date)}
              onChange={(e) => handleChange("date", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="grai">GRAI</label>
            <input
              type="text"
              className="form-control"
              id="grai"
              placeholder="Enter the GRAI..."
              value={packagingRecord && packagingRecord.grai}
              onChange={(e) => handleChange("grai", e.target.value, false)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="gtin">GTIN</label>
            <input
              type="text"
              className="form-control"
              id="gtin"
              placeholder="Enter the GTIN..."
              value={packagingRecord && packagingRecord.gtin}
              onChange={(e) => handleChange("gtin", e.target.value, false)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="sscc">SSCC</label>
            <input
              type="text"
              className="form-control"
              id="sscc"
              placeholder="Enter the SSCC..."
              value={packagingRecord && packagingRecord.sscc}
              onChange={(e) => handleChange("sscc", e.target.value, false)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Packaging Type *</label>
            <select
              className="form-control form-control-lg"
              id="packagingType"
              value={packagingRecord && packagingRecord.packagingType}
              onChange={(e) => handleChange("packagingType", e.target.value, true)}
            >
              <option value="">Select Packaging Type...</option>
              {packagingTypeList && packagingTypeList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Packaging Material *</label>
            <select
              className="form-control form-control-lg"
              id="packagingMaterial"
              value={packagingRecord && packagingRecord.packagingMaterial}
              onChange={(e) => handleChange("packagingMaterial", e.target.value, true)}
            >
              <option value="">Select Packaging Material...</option>
              {packagingMaterialList && packagingMaterialList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="quantity">Quantity Packaged *</label>
            <input
              type="number"
              className="form-control"
              id="quantityPackaged"
              placeholder="Enter the quantity packaged..."
              value={packagingRecord && packagingRecord.quantityPackaged}
              onChange={(e) => handleChange("quantityPackaged", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="quantityUnit">Quantity Unit *</label>
            <select
              className="form-control form-control-lg"
              id="quantityUnit"
              value={packagingRecord && packagingRecord.quantityUnit}
              onChange={(e) => handleChange("quantityUnit", e.target.value, true)}
            >
              <option value="">Select Quantity Type...</option>
              {quantityUnitList && quantityUnitList.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="appliedBy">Packaged By *</label>
            <input
              type="text"
              className="form-control"
              id="packagedBy"
              placeholder="Enter the Packaged by..."
              value={packagingRecord && packagingRecord.packagedBy}
              onChange={(e) => handleChange("packagedBy", e.target.value, true)}
            />
            <div className="invalid-feedback">This is required</div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Supervised By</label>
            <input
              type="text"
              className="form-control"
              id="supervisedBy"
              placeholder="Enter the supervised by..."
              value={packagingRecord && packagingRecord.supervisedBy}
              onChange={(e) => handleChange("supervisedBy", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Quality Checks</label>
            <input
              type="text"
              className="form-control"
              id="qualityChecks"
              placeholder="Enter the quality checks..."
              value={packagingRecord && packagingRecord.qualityChecks}
              onChange={(e) => handleChange("qualityChecks", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Issues Identified</label>
            <textarea
              rows="2"
              className="form-control"
              id="issuesIdentified"
              placeholder="Enter the issues identified..."
              value={packagingRecord && packagingRecord.issuesIdentified}
              onChange={(e) => handleChange("issuesIdentified", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Corrective Actions</label>
            <textarea
              rows="2"
              className="form-control"
              id="correctiveActions"
              placeholder="Enter the corrective actions..."
              value={packagingRecord && packagingRecord.correctiveActions}
              onChange={(e) => handleChange("correctiveActions", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="labelling">Labelling</label>
            <input
              type="text"
              className="form-control"
              id="labelling"
              placeholder="Enter the labelling..."
              value={packagingRecord && packagingRecord.labelling}
              onChange={(e) => handleChange("labelling", e.target.value, false)}
            />
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-4 col-12">
          <div className="form-group">
            <label htmlFor="lastName">Packaging Details</label>
            <textarea
              rows="2"
              className="form-control"
              id="packagingDetails"
              placeholder="Enter the packaging details..."
              value={packagingRecord && packagingRecord.packagingDetails}
              onChange={(e) => handleChange("packagingDetails", e.target.value, false)}
            />
          </div>
        </div>
      </div>
      <br />
      <EnvironmentalConditionNew transferRecord={packagingRecord} setTransferRecord={setPackagingRecord} />
      {/* <EnvironmentalCondition environmentalCondition={environmentalCondition} setEnvironmentalCondition={setEnvironmentalCondition} /> */}
      <hr />
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 ">
          <div className="float-end">
            <button type="button" className="btn btn-primary float-end"
              onClick={(e) => { if(validateFields()) { handleSubmit(e); }}}>
              Submit
            </button>
          </div>
        </div>
      </div>
  {/* Row end */ }
    </>
  );
}

export default PackagingBasicForm;